import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import s from './PreOrderMessage.scss';

@withGlobalProps
export class PreOrderMessage extends React.Component<ProvidedGlobalProps> {
  public render(): JSX.Element {
    return (
      <div className={s.preOrderMessage} data-hook="pre-order-message">
        {'PRE_ORDER_MESSAGE_COME_HERE'}
      </div>
    );
  }
}
